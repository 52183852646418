import { zipWith, values, join, keys, replace } from 'lodash';
import { toast } from 'react-toastify';

import { store } from '../index';
import { logout } from '../auth/actions/auth.actions';
// const url = 'http://localhost:3001';
// const url = 'https://api.over-wms.com';
const url = "https://api-wms-test.overcloud.usermd.net"

class Api {

    put(route, params) {
        return this.api(route, params, 'PUT', true);
    }

    delete(route, params) {
        return this.api(route, params, 'DELETE', true);
    }

    post(route, params, auth, file, customUrl, customHeaders, responseType) {
        return this.api(route, params, 'POST', auth, file, responseType, customUrl, customHeaders);
    }

    get(route, params, responseType) {
        if (params) {
            params = zipWith(keys(params), values(params), (key, val) => {
                return `${key}=${replace(val, '+', '%2B', 'gi')}`
            });
            route += '?' + join(params, '&');
        }
        return this.api(route, null, 'GET', true, null, responseType);
    }

    api(route, params, method, auth = true, file, responseType = 'json', customUrl, customHeaders) {
        let headers = null;
        let token = localStorage.getItem('JWT');
        if (customHeaders) {
            headers = customHeaders;
        } else {
            if (auth && !file) headers =
            {
                'Content-Type': 'application/json',
                'authorization': `JWT:${token}`
            }
            else if (!auth && !file) headers = {
                'Content-Type': 'application/json'
            }
            else headers = {
                'authorization': `JWT:${token}`
            }
        }

        params = params ? params : null;
        const options = Object.assign({ method: method }, { headers: headers }, params ? { body: file ? params : JSON.stringify(params) } : null);
        route = `${customUrl ? customUrl : url}${route}`;
        try {
            return fetch(route, options).then(res => {
                switch (res.status) {
                    case 401: {
                        store.dispatch(logout());
                        toast("Brak autoryzacji");
                        return res.json();
                    }
                    case 403: {
                        toast("Brak uprawnień");
                        return res.json();
                    }
                    case 422: {
                        toast("Wystąpił błąd");
                        return res.json();
                    }
                    case 404: {
                        toast("Nie znaleziono zasobu");
                        return res.json();
                    }
                    case 452: {
                        toast("Niekompatybilny zasób");
                        return res.json();
                    }
                    case 500: {
                        toast("Błąd systemu");
                        return Promise.reject();
                    }
                    default: {
                        if (responseType === 'json') {
                            return res.json();
                        } else {
                            return res.blob();
                        }
                    }
                }
            });
        } catch (error) {
            console.error(error)
        }
    }
}

export default new Api();